import f from "@/utils/network/gql/f";
import graphDatabaseRequestStrict from "@/utils/network/request/presets/graphDatabaseRequestStrict";
import mutation, { Vars } from "./mutations/mutationEmail";

const queryString = mutation("AddEmail")((vars) => [
  f("addEmail", [
    ["idOrEmail", vars.idOrEmail],
    ["email", vars.email],
  ])(["userId"]),
]);

export type Data = {
  addEmail: {
    userId: string;
  };
};

const addEmail = (token: string, vars: Vars) =>
  graphDatabaseRequestStrict<Data, Vars>(queryString, vars, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

export default addEmail;
