import { Email, Phone, User } from "@/types/api/complex";

const getInitials = (user: User): string => {
  const { firstName, lastName } = user;
  if (firstName && lastName) {
    const initials = firstName.slice(0, 1) + lastName.slice(0, 1);
    return initials.toLocaleUpperCase();
  }
  const email = user?.emails?.find((email) => email.primary);
  return email?.normalized?.slice(0, 2).toUpperCase() || "";
};
const getRegisterDate = (user: User): string => {
  return new Date(user?.activated || "").toLocaleDateString();
};
const getNames = (user: User) => {
  return {
    firstName: user?.firstName || "",
    lastName: user?.lastName || "",
  };
};
const getPrimaryEmail = (user: User): string => {
  return (
    user?.emails?.find((email) => email.primary)?.normalized || user?.id || ""
  );
};
const getPreferredLanguage = (user: User): string => {
  return user?.preferredLanguage || "";
};
const getVersion = (user: User): number => {
  return user?.version || 0;
};
const getPhones = (user: User): Phone[] => {
  return user?.phones || [];
};
const getEmails = (user: User): Email[] => {
  return user?.emails || [];
};
const getEstates = (user: User): any[] => {
  return user?.favoriteEstates || [];
};
const isLogged = (user: User): "yes" | "no" => {
  if (user && Object.keys(user).length !== 0 && user.constructor === Object) {
    return "yes";
  } else return "no";
};
const getNumberOfSearchWatches = (user: User): number | undefined => {
  if (!user.savedSearches) return undefined;
  let number = 0;
  user.savedSearches?.forEach((search) => {
    number += search.watches.length || 0;
  });
  return number;
};
const getRegistrationDate = (user: User): string | undefined => {
  if (user.activated)
    return new Date(user.activated).toISOString().slice(0, 10);
  return undefined;
};
export {
  getInitials,
  getRegisterDate,
  getNames,
  getPrimaryEmail,
  getPreferredLanguage,
  getVersion,
  getPhones,
  getEmails,
  getEstates,
  isLogged,
  getNumberOfSearchWatches,
  getRegistrationDate,
};
