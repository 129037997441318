import styles from "./loginform.module.css";
import { FunctionComponent, useEffect, useState } from "react";
import LoginInput from "../LoginInput";

import { useDispatch } from "react-redux";
import {
  setUserToken,
  setUserRefreshToken,
  setIsLoginFormOpen,
} from "@/redux/user/auth";
import { useRouter } from "next/router";

import {
  isEmailValid,
  isPassValid,
  isPass2Valid,
  AreInputsValid,
} from "@/utils/validations/inputLoginValidations";

import {
  getWithEmailMessage,
  getWithPassesMessages,
} from "@/utils/validations/inputLoginErrors";

import { handleRegisterWithEmail } from "@/utils/user/registration";
import { handleLoginWithEmail } from "@/utils/user/logins";
import { fetchUser } from "@/components/EstateButtons/Modal/help";

interface Props {
  isRegister: boolean;
  setSent: (b: boolean) => void;
  setError: (e: string) => void;
}
const LoginForm: FunctionComponent<Props> = ({
  isRegister,
  setSent,
  setError,
}) => {
  const router = useRouter();
  const dispatch = useDispatch();
  const [inputs, setInputs] = useState({ email: "", pass: "", pass2: "" });
  const [messages, setMessages] = useState({ email: "", pass: "", pass2: "" });

  const { email, pass, pass2 } = inputs;

  useEffect(() => {
    setMessages(getWithEmailMessage(messages, email));
  }, [inputs.email]);

  useEffect(() => {
    setMessages(getWithPassesMessages(messages, pass, pass2));
  }, [inputs.pass, inputs.pass2]);

  const handleAutoFill = (name: string, e: any) => {
    setInputs({ ...inputs, [name]: e.target?.value });
  };
  const register = async () => {
    const result = await handleRegisterWithEmail(email, pass);
    if (result === true) {
      setSent(true);
    } else setError(result);
  };
  const login = async () => {
    const result = await handleLoginWithEmail(email, pass);
    if (result.ok) {
      dispatch(setUserToken(result.token));
      dispatch(setUserRefreshToken(result.refreshToken));
      await fetchUser(result.token, dispatch);
      dispatch(setIsLoginFormOpen(false));
    } else setError(result.mess);
  };
  const handleFormSubmit = () => {
    if (isRegister) {
      register();
    } else {
      login();
    }
  };
  return (
    <form>
      <LoginInput
        id="email"
        onChange={(value) => setInputs({ ...inputs, email: value })}
        handleAutoFill={handleAutoFill}
        isValid={isEmailValid(email)}
        placeholder="E-mail*"
        eye={false}
        message={messages.email}
      />
      <LoginInput
        id="pass"
        onChange={(value) => setInputs({ ...inputs, pass: value })}
        handleAutoFill={handleAutoFill}
        isValid={isPassValid(pass)}
        placeholder="Heslo*"
        message={messages.pass}
      />
      {isRegister && (
        <LoginInput
          id="pass2"
          onChange={(value) => setInputs({ ...inputs, pass2: value })}
          handleAutoFill={handleAutoFill}
          isValid={isPass2Valid(pass, pass2)}
          placeholder="Zopakuj heslo*"
          message={messages.pass2}
        />
      )}
      {!isRegister && (
        <div
          className={styles.modalForgotten}
          onClick={() => router.push("/me-bydleni/zapomenute-heslo")}
        >
          {" "}
          Zabudnuté heslo{" "}
        </div>
      )}
      <div className="mt-2">
        Prihlásením k účtu vyjadrujete súhlas so{" "}
        <span className={styles.modalParagraphSpan}>
          Zásadami pre ochranu osobných údajov
        </span>{" "}
        a{" "}
        <span className={styles.modalParagraphSpan}>Podmienkami použitia</span>{" "}
        společnosti FLAT ZONE s.r.o.
      </div>
      <div
        className={`${styles.modalLoginContainer} ${
          !AreInputsValid(isRegister, email, pass, pass2) ? styles.disabled : ""
        }`}
      >
        <div className={styles.modalLogin} onClick={handleFormSubmit}>
          {isRegister ? "POSLAŤ AKTIVAČNÝ EMAIL" : "PRIHLÁSIŤ"}
        </div>
      </div>
    </form>
  );
};
export default LoginForm;
