const mapArraysToObject = <A1 extends string, A2>(
  a1: Readonly<A1[]>,
  a2: Readonly<A2[]>,
) => {
  type O = Record<A1, A2>;

  return a1.reduce((o: O, v, i) => {
    o[v] = a2[i];
    return o;
  }, {} as O);
};

export default mapArraysToObject;
