import { FunctionComponent, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import styles from "./headbutton.module.css";
import { getUserToken } from "@/redux/user/auth/selectors";
import { getInitials } from "@/utils/user/getters";
import UserIcon from "@/svg/UserIcon";

import { handleFetchCurrentUser } from "@/utils/user/handleRequests";
import { setUserData } from "@/redux/user/auth";
import { useRouter } from "next/router";

type Props = {
  toggleClass: () => void;
};

const HeadButton: FunctionComponent<Props> = ({ toggleClass }) => {
  const router = useRouter();
  const token = useSelector(getUserToken);
  const [user, setUser] = useState<any>();
  const dispatch = useDispatch();

  const fetchUser = async () => {
    if (token) {
      try {
        const result = await handleFetchCurrentUser(token);
        if (result.ok) {
          setUser(result.user);
          dispatch(setUserData(result.user || {}));
        }
        // eslint-disable-next-line
      } catch (e) {}
    }
  };
  useEffect(() => {
    fetchUser();
  }, [token]);

  const handleClick = () => {
    if (user) {
      router.push("/me-bydleni/prehled/");
    } else {
      toggleClass();
    }
  };

  return (
    <div>
      <div onClick={handleClick} className={styles.button}>
        {user && <span className={styles.initials}>{getInitials(user)}</span>}
        <UserIcon />
      </div>
    </div>
  );
};

export default HeadButton;
