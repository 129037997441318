import styles from "./studio.module.css";

const StudioIcon = () => {
  return (
    <>
      <div className={styles.container}>
        <svg
          version="1.1"
          id="studio-icon"
          xmlns="http://www.w3.org/2000/svg"
          x="0px"
          y="0px"
          width="55px"
          height="55px"
          viewBox="0 0 70.2 58.7"
          enableBackground="new 0 0 28.289 21.049"
          className={styles.svg}
        >
          <path
            className={styles.icon_green}
            fill="currentColor"
            d="M67.1,0h-64C1.4,0,0,1.4,0,3.1v41.2c0,1.7,1.4,3.1,3.1,3.1h22.8c-0.4,2.3-1.4,6.8-3.8,9.2
    	c-0.4,0.4-0.5,0.9-0.3,1.4c0.2,0.5,0.7,0.8,1.2,0.8h24.2c0.5,0,1-0.3,1.2-0.8c0.2-0.5,0.1-1-0.3-1.4c-2.4-2.4-3.4-6.9-3.8-9.2h22.8
    	c1.7,0,3.1-1.4,3.1-3.1V3.1C70.2,1.4,68.8,0,67.1,0 M3.1,2.5h64c0.3,0,0.6,0.3,0.6,0.6v34.6H2.5V3.1C2.5,2.8,2.8,2.5,3.1,2.5
    	 M44.7,56.2H25.5c1.9-3.1,2.6-6.9,2.9-8.8h13.3C42,49.3,42.8,53.1,44.7,56.2 M67.1,44.9h-6.2v-1c0-0.7-0.6-1.3-1.3-1.3
    	c-0.7,0-1.3,0.6-1.3,1.3v1h-5v-1c0-0.7-0.6-1.3-1.3-1.3c-0.7,0-1.3,0.6-1.3,1.3v1h-8H27.4H3.1c-0.3,0-0.6-0.3-0.6-0.6v-4h65.2v4
    	C67.7,44.6,67.4,44.9,67.1,44.9"
          ></path>
          <path
            className={styles.icon_green}
            fill="currentColor"
            d="M40.9,18.5c-0.2-0.1-0.3-0.3-0.3-0.5c-0.1-0.4,0.1-0.8,0.6-0.9l15.7-3.9c0.4-0.1,0.8,0.1,0.9,0.6
        c0.1,0.4-0.1,0.8-0.6,0.9l-15.7,3.9C41.2,18.6,41,18.6,40.9,18.5"
          ></path>
          <path
            className={styles.icon_green}
            fill="currentColor"
            d="M11.5,25.4c0.8,0,1.5-0.4,1.8-1.1L23.6,27c0.2,1,1.1,1.8,2.2,1.8c1.2,0,2.1-1,2.1-2.2l0-0.3l10.4-6.2l-0.7-1.3
        L27.1,25c-0.4-0.4-0.9-0.6-1.5-0.6c-0.8,0-1.5,0.5-1.8,1.1l-10.2-2.7c-0.2-1-1.1-1.8-2.2-1.8c-1.2,0-2.1,1-2.1,2.2
        C9.4,24.4,10.3,25.4,11.5,25.4 M25.7,25.9c0.4,0,0.7,0.3,0.7,0.7c0,0.4-0.3,0.7-0.7,0.7c-0.4,0-0.7-0.3-0.7-0.7l0-0.1l0,0
        C25.1,26.2,25.3,26,25.7,25.9 M11.5,22.5c0.4,0,0.7,0.3,0.7,0.7c0,0.4-0.3,0.7-0.7,0.7c-0.4,0-0.7-0.3-0.7-0.7
        C10.8,22.8,11.1,22.5,11.5,22.5"
          ></path>
          <path
            className={`${styles.icon_green} ${styles.heading_1}`}
            fill="currentColor"
            d="M58.7,11.3c-1.2,0-2.1,1-2.1,2.1c0,1.2,1,2.1,2.1,2.1c1.2,0,2.1-1,2.1-2.1C60.8,12.2,59.9,11.3,58.7,11.3
        L58.7,11.3z"
          ></path>
          <path
            className={`${styles.icon_green} ${styles.heading_1}`}
            fill="currentColor"
            d="M39.4,16.5c-1.2,0-2.1,1-2.1,2.1c0,1.2,1,2.1,2.1,2.1c1.2,0,2.1-1,2.1-2.1C41.5,17.4,40.6,16.5,39.4,16.5
        L39.4,16.5z"
          ></path>
        </svg>
        <div className={styles.navigation_heading}>
          Studio
          <br />
          <br />
        </div>
      </div>
    </>
  );
};

export default StudioIcon;
