import { FunctionComponent, useState, useEffect } from "react";
import styles from "./leftMenu.module.css";
import FooterColumnGeneric from "../Footer/Column/Generic";
import columns from "../../data/columns.json";
import Logo from "@/svg/logo.svg";

import Facebook from "@/svg/facebook.svg";
import Twitter from "@/svg/twitter.svg";
import Instagram from "@/svg/instagram.svg";

type Props = {
  className?: string;
};

const LeftMenu: FunctionComponent<Props> = ({ className }) => {
  const [showMenu, setShowMenu] = useState(false);

  useEffect(() => {
    const html = document.querySelector("html");
    if (html === null) return;
    if (showMenu) {
      html.style.overflowY = "hidden";
    } else {
      html.style.overflowY = "scroll";
    }
  }, [showMenu]);

  return (
    <div className={styles.leftMenu}>
      <div
        className={`${styles.hamburger} ${className}`}
        onClick={() => setShowMenu(true)}
      >
        <div></div>
        <div></div>
        <div></div>
      </div>
      <div className={`${styles.menu} ${showMenu && `${styles.active}`}`}>
        <a className={styles.logo} href={"/"}>
          <Logo />
        </a>
        <div className={styles.navs}>
          {columns.map((column) => (
            <FooterColumnGeneric
              key={column.title}
              title={column.title}
              items={column.items}
            />
          ))}
        </div>
        <div className={styles.footer}>
          <div className={styles.line}></div>
          <div className={styles.container}>
            <h3>CZ</h3>
            <div>
              <Facebook />
              <Twitter />
              <Instagram />
            </div>
          </div>
        </div>
        <div className={styles.cross} onClick={() => setShowMenu(false)}>
          +
        </div>
      </div>
    </div>
  );
};

export default LeftMenu;
