import { View } from "@/types/api/derived";

export type GoogleViewKeys =
  | "square-projects"
  | "square-detail"
  | "square"
  | "table"
  | "row";

const MAP: Record<
  Exclude<View, "map">,
  Exclude<GoogleViewKeys, "square-detail">
> = {
  projects: "square-projects",
  cards: "square",
  rows: "row",
  table: "table",
};

// @ts-ignore
const mapView = (key: View): GoogleViewKeys => MAP[key] || "square-detail";

export default mapView;
