import f from "@/utils/network/gql/f";
import graphDatabaseRequestStrict from "@/utils/network/request/presets/graphDatabaseRequestStrict";
import mutation, { Vars } from "./mutations/mutationCreateEstateWatch";

const queryString = mutation("CreateEstateWatch")((vars) => [
  f("createEstateWatch", [
    ["estateId", vars.estateId],
    ["field", vars.field],
    ["notifyPeriod", vars.notifyPeriod],
    ["source", `"${process.env.NEXT_PUBLIC_API_PRICELIST}"`],
  ])(["watchId"]),
]);

export type Data = {
  createEstateWatch: {
    watchId: string;
  };
};

const createEstateWatch = (token: string, vars: Vars) =>
  graphDatabaseRequestStrict<Data, Vars>(queryString, vars, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

export default createEstateWatch;
