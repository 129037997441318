import styles from "./generic.module.css";
import shared from "../column.module.css";
import Link from "next/link";

type Props = {
  title: string;
  items: {
    title: string;
    link: string;
  }[];
};

const FooterColumnGeneric = ({ title, items }: Props) => {
  return (
    <div className={shared.column}>
      <h4 className={styles.title}>{title}</h4>

      <div className={styles.list}>
        {items.map((item, i) => (
          <div className={styles.item} key={item.link + i}>
            <Link href={item.link} legacyBehavior>
              {item.title}
            </Link>
          </div>
        ))}
      </div>
    </div>
  );
};

export default FooterColumnGeneric;
