import f from "@/utils/network/gql/f";
import graphDatabaseRequestStrict from "@/utils/network/request/presets/graphDatabaseRequestStrict";
import mutation, { Vars } from "./mutations/mutationSendPasswordResetEmail";

const queryString = mutation("SendPasswordResetEmail")((vars) => [
  f("sendPasswordResetEmail", [["email", vars.email]])(["email"]),
]);

export type Data = {
  sendPasswordResetEmail: {
    email: string;
  };
};

const sendPasswordResetEmail = (vars: Vars) =>
  graphDatabaseRequestStrict<Data, Vars>(queryString, vars);

export default sendPasswordResetEmail;
