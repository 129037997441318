import mapSort from "@/utils/google/mapSort";
import mapView from "@/utils/google/mapView";
import mapLevel from "@/utils/google/mapLevel";
import mapDispositions from "@/utils/google/mapDispositions";
import createEventCategory from "@/utils/google/createEventCategory";
import createEventList from "@/utils/google/createEventList";
import { Maybe } from "@/types/general";
import { EstateField, EstateNotifyPeriod, Settings } from "@/types/api/complex";
import { Price } from "@/types/api/simple";
import pushEvent from "@/utils/other/pushEvent";

export type Data = {
  event: "saveWatch" | "removeWatch";
  section: "list" | "admin";
  field: EstateField;
  period: EstateNotifyPeriod;
  project: string;
  developer: string;
  price: Price;
  id: string;
  variant: string;
  count?: number;
  settings?: Maybe<Settings>;
};

export const pushWatchObject = ({
  event,
  section,
  field,
  period,
  project,
  developer,
  count,
  price,
  id,
  variant,
  settings,
}: Data) => {
  if (count) count += 1;
  const offerTypes = settings?.current?.offerTypes || ["SALE"];
  const estateTypes = settings?.current.estateTypes || ["FLAT", "HOUSE"];
  const locations = (settings?.current.breadcrumbs as string[]) || [];
  const sort = mapSort(settings?.current.sort || "top");
  const view = mapView(settings?.current.view || "projects");
  const location = settings?.current.location;
  const level = mapLevel(location);
  const dispositions = mapDispositions(settings?.current.dispositions);
  const changed = false;

  const category = createEventCategory(true, {
    offerTypes,
    estateTypes,
    locations,
    count,
  });

  const list = createEventList({
    estateTypes,
    location,
    dispositions,
    price: settings?.current.price,
    changed,
    sort,
    view,
    level,
  });
  const listObject =
    section === "list"
      ? { list, position: count, dimension13: `${project} ${developer}` }
      : {};

  setTimeout(() => {
    pushEvent({
      event,
      section,
      field,
      period,
      product: {
        name: project,
        brand: developer,
        category: settings ? category : null,
        id,
        price,
        variant,
        ...listObject,
      },
    });
  }, 0);
};
export const pushWatchObjects = (data: Data[]) => {
  data.forEach((item) => {
    pushWatchObject(item);
  });
};
