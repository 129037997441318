import styles from "./novostaveb.module.css";

const NovostavebIcon = () => {
  return (
    <>
      <div className={styles.container}>
        <svg
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          x="0px"
          y="0px"
          width="55px"
          height="55px"
          viewBox="0 0 26.1 40.9"
          enableBackground="new 0 0 26.1 40.9"
          className={styles.svg}
        >
          <path
            fill="currentColor"
            d="M17.1,13.1l-0.3-0.1l-0.3,0.1l-2,1.1c0.5,0.2,0.9,0.5,1.3,0.9l1-0.5l3.6,1.9c0.2,0.1,0.5,0.1,0.7-0.1
	c0.2-0.2,0.3-0.4,0.3-0.7l-0.7-4l2.9-2.9c0.2-0.2,0.3-0.5,0.2-0.7c-0.1-0.2-0.3-0.4-0.6-0.5l-4-0.6l-1.8-3.7C17.3,3.2,17,3,16.8,3
	c-0.3,0-0.5,0.1-0.6,0.4l-1.8,3.7l-4,0.6c-0.3,0-0.5,0.2-0.6,0.5c-0.1,0.2,0,0.5,0.2,0.7l2.9,2.9l-0.3,1.8c0.4,0.1,0.9,0.3,1.3,0.5
	l0.4-2.4c0-0.2,0-0.5-0.2-0.6l-2.2-2.1l3-0.4c0.2,0,0.4-0.2,0.5-0.4l1.4-2.7L18.1,8c0.1,0.2,0.3,0.3,0.5,0.4l3,0.4l-2.2,2.1
	c-0.2,0.2-0.2,0.4-0.2,0.6l0.5,3L17.1,13.1z"
            className={styles.icon_green}
          />

          <g className={styles["outer-circle"]}>
            <path
              fill="currentColor"
              d="M10.1,15.8c3.6,0,6.5,2.9,6.5,6.5s-2.9,6.5-6.5,6.5c-3.6,0-6.5-2.9-6.5-6.5S6.5,15.8,10.1,15.8"
              className={`${styles.inner_circle} ${styles.icon_green}`}
            />
          </g>

          <path
            fill="currentColor"
            d="M20.4,40.9c-1.2,0-2.5-0.6-3.3-1.7l-5-7c-3.9,0.8-7.9-0.7-10.3-4c-3.2-4.5-2.2-10.8,2.3-14l0,0
    	c2.2-1.6,4.8-2.2,7.5-1.7c2.6,0.4,5,1.9,6.5,4.1c2.3,3.2,2.5,7.6,0.4,11l5,7c1.3,1.8,0.9,4.3-0.9,5.6C22,40.6,21.2,40.9,20.4,40.9z
    	 M12.4,30.6c0.2,0,0.5,0.1,0.6,0.3l5.3,7.4c0.8,1.1,2.4,1.4,3.5,0.6c1.1-0.8,1.4-2.4,0.6-3.5L17.1,28c-0.2-0.3-0.2-0.6,0-0.9
    	c2-2.9,1.9-6.8-0.1-9.7c-1.3-1.9-3.3-3.1-5.5-3.5c-2.2-0.4-4.5,0.1-6.4,1.5l0,0c-3.8,2.7-4.7,8.1-2,11.9c2.1,2.9,5.7,4.2,9.2,3.3
    	C12.3,30.6,12.4,30.6,12.4,30.6z"
            className={styles.icon_orange}
          />

          <path
            fill="currentColor"
            d="M25.1,5.1c0.1-0.1,0.1-0.2,0.1-0.3l-0.3-1.6L26,2.2c0.1-0.1,0.1-0.2,0.1-0.3c0-0.1-0.1-0.2-0.2-0.2l-1.6-0.2
    	l-0.7-1.4c0-0.1-0.1-0.1-0.2-0.1c-0.1,0-0.2,0.1-0.2,0.1l-0.7,1.4l-1.6,0.2c-0.1,0-0.2,0.1-0.2,0.2c0,0.1,0,0.2,0.1,0.3l1.1,1.1
    	l-0.3,1.6c0,0.1,0,0.2,0.1,0.3c0,0,0.1,0.1,0.2,0.1l0.1,0l1.4-0.7l1.4,0.7C24.9,5.2,25,5.2,25.1,5.1"
            className={styles.icon_green}
          />
        </svg>

        <div className={styles.navigation_heading}>
          Vyhledávač<br></br>novostaveb
        </div>
      </div>
    </>
  );
};

export default NovostavebIcon;
