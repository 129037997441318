import NavLink from "@/components/NavLink";
import StudioIcon from "@/svg/StudioIcon";

const Studio = () => {
  return (
    <NavLink href={"https://studio.flatzone.cz/"}>
      <StudioIcon />
    </NavLink>
  );
};

export default Studio;
