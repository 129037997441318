import {
  isEmailValid,
  isPassValid,
  isPass2Valid,
} from "@/utils/validations/inputLoginValidations";

interface messages {
  email: string;
  pass: string;
  pass2: string;
}
const getWithEmailMessage = (messages: messages, email: string) => {
  const newMessages = { ...messages };

  if (isEmailValid(email)) {
    newMessages.email = "";
  } else {
    newMessages.email = "Zadej platnou e-mailovou adresu.";
  }
  return newMessages;
};

const getWithPassesMessages = (
  messages: messages,
  pass: string,
  pass2: string,
) => {
  const newMessages = { ...messages };

  //check pass
  if (isPassValid(pass)) {
    newMessages.pass = "";
  } else {
    newMessages.pass =
      "Heslo musí obsahovat nejméně 8 znaků a alespoň jedno malé písmeno, jedno velké písmeno a jednu číslici.";
  }
  //check pass2
  if (isPass2Valid(pass, pass2)) {
    newMessages.pass2 = "";
  } else {
    newMessages.pass2 = "Hesla se neshodují.";
  }

  return newMessages;
};

export { getWithEmailMessage, getWithPassesMessages };
