import m from "@/utils/network/gql/m";

import { EstateField, EstateNotifyPeriod } from "@/types/api/complex";

export type Vars = {
  estateId: string;
  field: EstateField;
  notifyPeriod: EstateNotifyPeriod;
};

export const VARS = {
  estateId: "ID!",
  field: "EstateWatchField!",
  notifyPeriod: "NotifyPeriod!",
};

const mutation = (name: string) => m(name, VARS);

export default mutation;
