import m from "@/utils/network/gql/m";

export type Vars = {
  idOrEmail: string;
  number: string;
};

export const VARS = {
  idOrEmail: "String!",
  number: "String!",
};

const mutation = (name: string) => m(name, VARS);

export default mutation;
