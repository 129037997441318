import loginWithEmail from "@/api/user/loginWithEmail";
import { handleFetchCurrentUser } from "./handleRequests";
import pushEvent from "@/utils/other/pushEvent";

const handleLoginWithEmail = async (
  usernameOrEmail: string,
  password: string,
) => {
  const { data, errors } = await loginWithEmail({
    usernameOrEmail,
    password,
  });
  const response = {
    ok: false,
    mess: "",
    token: "",
    refreshToken: "",
  };
  if (errors)
    return {
      ...response,
      ok: false,
      mess: errors[0].message || "Nastala chyba",
    };

  const userData = await handleFetchCurrentUser(data.login.token);
  pushEvent({
    event: "loginSuccess",
    method: "mail",
    user: {
      isLogged: "yes",
      id: userData?.user?.id || null,
    },
  });

  const { token, refreshToken } = data.login;
  return { ...response, ok: true, token, refreshToken };
};

export { handleLoginWithEmail };
