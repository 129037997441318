import f from "@/utils/network/gql/f";
import graphDatabaseRequestStrict from "@/utils/network/request/presets/graphDatabaseRequestStrict";
import mutation, { Vars } from "./mutations/mutationOnlyEmail";

const queryString = mutation("SendVerificationEmail")((vars) => [
  f("sendVerificationEmail", [["email", vars.email]])(["email"]),
]);

export type Data = {
  sendVerificationEmail: {
    email: string;
  };
};

const sendVerificationEmail = (token: string, vars: Vars) =>
  graphDatabaseRequestStrict<Data, Vars>(queryString, vars, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

export default sendVerificationEmail;
