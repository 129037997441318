import { FunctionComponent } from "react";
import styles from "./footer.module.css";
import columns from "../../data/columns.json";
import info from "../../data/info.json";
import FooterColumnGeneric from "./Column/Generic";
import FooterColumnInfo from "./Column/Info";

const Footer: FunctionComponent = () => {
  return (
    <div className={styles.box}>
      <div className={styles.list}>
        {columns.map((column) => (
          <FooterColumnGeneric
            key={column.title}
            title={column.title}
            items={column.items}
          />
        ))}

        <FooterColumnInfo
          company={info.company}
          address={info.address}
          contacts={info.contacts}
          socials={info.socials as any}
        />
      </div>
    </div>
  );
};

export default Footer;
