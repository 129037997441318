import { FunctionComponent } from "react";
import styles from "./message.module.css";

type Props = {
  children?: React.ReactNode;
  close: () => void;
};

const Message: FunctionComponent<Props> = ({ close, children }) => {
  return (
    <div className={styles.container}>
      <div onClick={close} className={styles.modalOverlay} />
      <div className={styles.modalContainer}>
        <div className={styles.close} onClick={close}>
          +
        </div>
        <div className={styles.modalSubcontainer}>{children}</div>
      </div>
    </div>
  );
};

export default Message;
