import f from "@/utils/network/gql/f";
import graphDatabaseRequestStrict from "@/utils/network/request/presets/graphDatabaseRequestStrict";
import mutation, { Vars } from "./mutations/mutationEstates";

const queryString = mutation("DeleteFavoriteEstates")((vars) => [
  f("deleteFavoriteEstates", [
    ["idOrEmail", vars.idOrEmail],
    ["estates", vars.estates],
    ["source", `"${process.env.NEXT_PUBLIC_API_PRICELIST}"`],
  ])(["userId"]),
]);

export type Data = {
  deleteFavoriteEstates: {
    userId: string;
  };
};

const deleteFavoriteEstates = (token: string, vars: Vars) =>
  graphDatabaseRequestStrict<Data, Vars>(queryString, vars, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

export default deleteFavoriteEstates;
