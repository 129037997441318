import { useState, FunctionComponent } from "react";
import Novostavby from "./Novostavby";
import Studio from "./Studio";
import styles from "./menu.module.css";

const NavigationMenu: FunctionComponent = () => {
  const [isActive, setActive] = useState(false);
  const icons = [Novostavby, Studio];

  return (
    <div className={styles.group}>
      <div className={styles.hamburger}>
        <div onClick={() => setActive(!isActive)}>
          <span className={styles.first} />
          <div className={styles.bar}>
            <div>
              <span className={styles.second} />
            </div>
            <div>
              <span className={styles.secondDot} />
            </div>
          </div>
          <div className={styles.bar}>
            <div>
              <span className={styles.thirdDot} />
            </div>
            <div>
              <span className={styles.third} />
            </div>
          </div>
        </div>
        <div
          className={`${
            isActive ? `${styles.showMenu}` : `${styles.hideMenu}`
          }`}
        >
          <div onClick={() => setActive(!isActive)}>
            <div className={styles.menuContainer}>
              <div className={styles.list}>
                {icons.map((Component, i) => {
                  return <Component key={i} />;
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className={`${
          isActive ? `${styles.showMenuOverlay}` : `${styles.hideMenuOverlay}`
        }`}
        onClick={() => setActive(!isActive)}
      />
    </div>
  );
};

export default NavigationMenu;
