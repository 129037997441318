import f from "@/utils/network/gql/f";
import graphDatabaseRequestStrict from "@/utils/network/request/presets/graphDatabaseRequestStrict";
import mutation, { Vars } from "./mutations/mutationEmail";

const queryString = mutation("SetPrimaryEmail")((vars) => [
  f("setPrimaryEmail", [
    ["idOrEmail", vars.idOrEmail],
    ["email", vars.email],
  ])(["userId"]),
]);

export type Data = {
  setPrimaryEmail: {
    userId: string;
  };
};

const setPrimaryEmail = (token: string, vars: Vars) =>
  graphDatabaseRequestStrict<Data, Vars>(queryString, vars, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

export default setPrimaryEmail;
