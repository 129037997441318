import { FunctionComponent, useState } from "react";
import styles from "./logininput.module.css";
import InputEye from "@/components/InputEye";

interface Props {
  id: string;
  onChange: (value: string) => void;
  handleAutoFill: (name: string, e: any) => void;
  isValid: boolean;
  message: string;
  placeholder: string;
  eye?: boolean;
}

const LoginInput: FunctionComponent<Props> = ({
  id,
  onChange,
  handleAutoFill,
  isValid,
  message,
  placeholder,
  eye = true,
}) => {
  const [isVisible, setVisible] = useState(!eye);

  const eyeClick = () => {
    setVisible(!isVisible);
  };
  return (
    <div>
      <div className={styles.inputWrapper}>
        <input
          onChange={(e) => onChange(e.target.value)}
          onAnimationStart={(e) => handleAutoFill(id, e)}
          className={`${styles.modalInput} ${isValid ? "" : styles.inputBad}`}
          placeholder={placeholder}
          type={isVisible ? "text" : "password"}
        />
        {eye && (
          <InputEye eyeClick={eyeClick} opacity={isVisible ? 0.7 : 0.4} />
        )}
      </div>
      {message && <span className={styles.errorLabel}> {message} </span>}
    </div>
  );
};
export default LoginInput;
