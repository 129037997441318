import f from "@/utils/network/gql/f";
import graphDatabaseRequestStrict from "@/utils/network/request/presets/graphDatabaseRequestStrict";
import mutation, { Vars } from "./mutations/mutationUpdatePersonalData";
import { User } from "@/types/api/complex";

const queryString = mutation("UpdatePersonalData")((vars) => [
  f("updatePersonalData", [
    ["idOrEmail", vars.idOrEmail],
    ["data", vars.data],
    ["version", vars.version],
  ])([f("user")(["id"])]),
]);

export type Data = {
  updatePersonalData: {
    user: User;
  };
};

const updatePersonalData = (token: string, vars: Vars) =>
  graphDatabaseRequestStrict<Data, Vars>(queryString, vars, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

export default updatePersonalData;
