import f from "@/utils/network/gql/f";
import graphDatabaseRequestStrict from "@/utils/network/request/presets/graphDatabaseRequestStrict";
import mutation, { Vars } from "./mutations/mutationResetPassword";

const queryString = mutation("ResetPassword")((vars) => [
  f("resetPassword", [
    ["password", vars.password],
    ["token", vars.token],
  ])(["userId, token, refreshToken"]),
]);

export type Data = {
  resetPassword: {
    userId: string;
    token: string;
    refreshToken: string;
  };
};

const resetPassword = (vars: Vars) =>
  graphDatabaseRequestStrict<Data, Vars>(queryString, vars);

export default resetPassword;
