import { SortOptionKeys } from "@/types/api/derived";

export type GoogleSortKeys = "lowToHigh" | "highToLow" | "top";

const MAP: Record<SortOptionKeys, GoogleSortKeys> = {
  top: "top",
  price_asc: "lowToHigh",
  price_desc: "highToLow",
};

const mapSort = (key: SortOptionKeys) => MAP[key];

export default mapSort;
