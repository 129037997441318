import registerWithEmail from "@/api/user/registerWithEmail";
import pushEvent from "@/utils/other/pushEvent";

const handleRegisterWithEmail = async (email: string, password: string) => {
  const { errors, data } = await registerWithEmail({
    email,
    password,
  });
  if (errors) return "Zlý email alebo heslo";
  pushEvent({
    event: "registrationSuccess",
    method: "mail",
    user: {
      isLogged: "no",
      id: data.register.userId,
    },
  });
  return true;
};

export { handleRegisterWithEmail };
