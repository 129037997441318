import query, { Vars } from "./queries/queryCurrentUser";
import f from "@/utils/network/gql/f";
import graphDatabaseRequestStrict from "@/utils/network/request/presets/graphDatabaseRequestStrict";
import { User } from "@/types/api/complex";

const queryString = query("CurrentUser")(() => [
  f("currentUser")([
    "id",
    "version",
    "created",
    "activated",
    "firstName",
    "lastName",
    "favoritePosts",
    "preferredLanguage",
    f("emails")(["normalized", "primary", "verified"]),
    f("phones")(["normalized", "primary"]),
    f("favoriteEstates", [
      ["source", `"${process.env.NEXT_PUBLIC_API_PRICELIST}"`],
      ["sortOptions", "$sortOptFavEstates"],
    ])(["id", f("watches")(["id", "field", "notifyPeriod"])]),
    f("savedSearches", [
      ["source", `"${process.env.NEXT_PUBLIC_API_PRICELIST}"`],
      ["sortOptions", "$sortOptSavFilters"],
    ])(["id", f("watches")(["id", "notifyPeriod"])]),
  ]),
]);

export type Data = {
  currentUser: User;
};
const defaultVars = {
  sortOptFavEstates: [],
  sortOptSavFilters: [],
};
const fetchCurrentUser = (token: string, vars: Vars = defaultVars) =>
  graphDatabaseRequestStrict<Data, Vars>(queryString, vars, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

export default fetchCurrentUser;
