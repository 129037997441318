import f from "@/utils/network/gql/f";
import graphDatabaseRequestStrict from "@/utils/network/request/presets/graphDatabaseRequestStrict";
import mutation, { Vars } from "./mutations/mutationChangePassword";

const queryString = mutation("ChangePassword")((vars) => [
  f("changePassword", [
    ["idOrEmail", vars.idOrEmail],
    ["newPassword", vars.newPassword],
    ["oldPassword", vars.oldPassword],
  ])(["userId"]),
]);

export type Data = {
  changePassword: {
    userId: string;
  };
};

const changePassword = (token: string, vars: Vars) =>
  graphDatabaseRequestStrict<Data, Vars>(queryString, vars, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

export default changePassword;
