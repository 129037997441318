import NavLink from "@/components//NavLink";
import NovostavebIcon from "@/svg/NovostavebIcon";

const Novostaveb = () => {
  return (
    <NavLink
      href={"/"}
      exact={true}
      other={[
        {
          href: "/novostavby",
          exact: false,
        },
      ]}
    >
      <NovostavebIcon />
    </NavLink>
  );
};

export default Novostaveb;
