import mapArraysToObject from "@/utils/object/mapArraysToObject";
import {
  DISPOSITION_KEYS,
  DISPOSITION_TRANSLATIONS,
  DISPOSITION_URLS,
  ESTATE_TYPE_KEYS,
  ESTATE_TYPE_URLS,
  OFFER_TYPE_KEYS,
  OFFER_TYPE_TRANSLATIONS,
  OFFER_TYPE_URLS,
  SORT_TYPE_KEYS,
  SORT_TYPE_OBJECTS,
} from "@/types/api/constants";
import {
  DispositionKeys,
  DispositionTranslations,
  DispositionUrls,
  EstateTypeKeys,
  EstateTypeUrls,
  OfferTypeKeys,
  OfferTypeTranslations,
  OfferTypeUrls,
  SortTypeKeys,
  SortTypeObjects,
} from "@/types/api/derived";

export const ESTATE_TYPE_MAP_KEYS_URLS = mapArraysToObject<
  EstateTypeKeys,
  EstateTypeUrls
>(ESTATE_TYPE_KEYS, ESTATE_TYPE_URLS);

export const ESTATE_TYPE_MAP_KEYS_TRANSLATIONS = mapArraysToObject<
  EstateTypeKeys,
  EstateTypeUrls
>(ESTATE_TYPE_KEYS, ESTATE_TYPE_URLS);

export const ESTATE_TYPE_MAP_URLS_KEYS = mapArraysToObject<
  EstateTypeUrls,
  EstateTypeKeys
>(ESTATE_TYPE_URLS, ESTATE_TYPE_KEYS);

export const OFFER_TYPE_MAP_KEYS_URLS = mapArraysToObject<
  OfferTypeKeys,
  OfferTypeUrls
>(OFFER_TYPE_KEYS, OFFER_TYPE_URLS);

export const OFFER_TYPE_MAP_KEYS_TRANSLATIONS = mapArraysToObject<
  OfferTypeKeys,
  OfferTypeTranslations
>(OFFER_TYPE_KEYS, OFFER_TYPE_TRANSLATIONS);

export const OFFER_TYPE_MAP_URLS_KEYS = mapArraysToObject<
  OfferTypeUrls,
  OfferTypeKeys
>(OFFER_TYPE_URLS, OFFER_TYPE_KEYS);

export const OFFER_TYPE_MAP_TRANSLATIONS_KEYS = mapArraysToObject<
  OfferTypeTranslations,
  OfferTypeKeys
>(OFFER_TYPE_TRANSLATIONS, OFFER_TYPE_KEYS);

export const DISPOSITION_MAP_KEYS_URL = mapArraysToObject<
  DispositionKeys,
  DispositionUrls
>(DISPOSITION_KEYS, DISPOSITION_URLS);

export const DISPOSITION_MAP_URL_KEYS = mapArraysToObject<
  DispositionUrls,
  DispositionKeys
>(DISPOSITION_URLS, DISPOSITION_KEYS);

export const DISPOSITION_MAP_KEYS_TRANSLATIONS = mapArraysToObject<
  DispositionKeys,
  DispositionTranslations
>(DISPOSITION_KEYS, DISPOSITION_TRANSLATIONS);

export const SORT_TYPE_MAP_KEYS_OBJECTS = mapArraysToObject<
  SortTypeKeys,
  SortTypeObjects
>(SORT_TYPE_KEYS, SORT_TYPE_OBJECTS);
