import f from "@/utils/network/gql/f";
import graphDatabaseRequestStrict from "@/utils/network/request/presets/graphDatabaseRequestStrict";
import mutation, { Vars } from "./mutations/mutationPhone";

const queryString = mutation("DeletePhone")((vars) => [
  f("deletePhone", [
    ["idOrEmail", vars.idOrEmail],
    ["number", vars.number],
  ])(["userId"]),
]);

export type Data = {
  deletePhone: {
    userId: string;
  };
};

const deletePhone = (token: string, vars: Vars) =>
  graphDatabaseRequestStrict<Data, Vars>(queryString, vars, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

export default deletePhone;
