import { FunctionComponent } from "react";
import Message from "@/components/Message";
import styles from "./registersuccess.module.css";

interface Props {
  close: () => void;
}

const RegisterSuccess: FunctionComponent<Props> = ({ close }) => {
  return (
    <Message close={close}>
      <div className={styles.modalHeading}>ZAREGISTRUJTE SE NA FLAT ZONE</div>
      <div className={styles.info}>
        Registrace proběhla, e-mail pro aktivaci odeslán.
      </div>
    </Message>
  );
};
export default RegisterSuccess;
