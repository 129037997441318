import { FunctionComponent } from "react";
import styles from "./page.module.css";
import Footer from "@/components/Footer";

type Props = {
  children?: React.ReactNode;
  navigation: FunctionComponent<any>;
};

const Page: FunctionComponent<Props> = ({
  navigation: Navigation,
  children,
}) => {
  return (
    <>
      <Navigation />
      <main className={styles.main}>{children}</main>
      <Footer />
    </>
  );
};

export default Page;
