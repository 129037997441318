import q from "@/utils/network/gql/q";

export type Vars = {
  id: string;
};

export const VARS = {
  id: "ID!",
};

const query = (name: string) => q(name, VARS);

export default query;
