import styles from "./info.module.css";
import shared from "../column.module.css";
import Link from "next/link";
import Facebook from "@/svg/facebook.svg";
import Twitter from "@/svg/twitter.svg";
import Instagram from "@/svg/instagram.svg";
import { FunctionComponent } from "react";

const SOCIALS = {
  facebook: Facebook,
  twitter: Twitter,
  instagram: Instagram,
};

type Props = {
  company: {
    name: {
      first: string;
      second: string;
      suffix: string;
    };
    infos: string[];
  };
  address: {
    link: string;
    values: string[];
  };
  contacts: {
    link: string;
    value: string;
  }[];
  socials: {
    link: string;
    name: "facebook" | "twitter" | "instagram";
  }[];
};

const FooterColumnInfo: FunctionComponent<Props> = ({
  company,
  address,
  contacts,
  socials,
}) => {
  return (
    <div className={`${styles.logo} ${shared.column}`}>
      <Link href={"/"} legacyBehavior>
        <div className={styles.logoText}>
          <span className={styles.logoOrange}>{company.name.first}</span>
          <span className={styles.logoGreen}>{company.name.second}</span>
          <span>{company.name.suffix}</span>
        </div>
      </Link>

      <a href={address.link} target="_blank" rel="noreferrer">
        {address.values.map((value, i) => (
          <div className={styles.address} key={i}>
            {value}
          </div>
        ))}
      </a>

      {contacts.map((contact, i) => (
        <a
          href={contact.link}
          className={styles.contact}
          key={contact.link + i}
        >
          {contact.value}
        </a>
      ))}

      {company.infos.map((info, i) => (
        <div className={styles.info} key={i}>
          {info}
        </div>
      ))}

      <div className={styles.socials}>
        {socials.map((social, i) => {
          const Icon = SOCIALS[social.name];

          return (
            <a
              className={styles.social}
              href={social.link}
              target="_blank"
              rel="noreferrer"
              key={social.link + i}
            >
              <Icon />
            </a>
          );
        })}
      </div>
    </div>
  );
};

export default FooterColumnInfo;
