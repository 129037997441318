import { setIsLoginFormOpen, setUserData } from "@/redux/user/auth";
import { EstateNotifyPeriod, Settings } from "@/types/api/complex";
import { handleFetchCurrentUser } from "@/utils/user/handleRequests";
import {
  handleDeleteWatch,
  handleCreateEstateWatch,
  handleAddFavoriteEstates,
} from "@/utils/user/handleEstateRequests";
import { getPrimaryEmail } from "@/utils/user/getters";
import { Maybe } from "@/types/general";

const setUpdatedMessage = (
  newMess: string,
  setMessage: (m: string) => void,
) => {
  const date = new Date();
  setMessage(
    `${newMess} - ${date.getHours()}:${date.getMinutes()}:${date.getSeconds()}`,
  );
};

export const toggleClass = (
  isActive: boolean,
  setActive: (b: boolean) => void,
  e: any,
  userData: any,
  dispatch: (p: any) => void,
) => {
  if (Object.keys(userData).length === 0 && userData.constructor === Object) {
    dispatch(setIsLoginFormOpen(true));
  } else {
    e.preventDefault();
    e.stopPropagation();
    setActive(!isActive);
  }
};
const deleteWatchPair = async (
  token: string,
  watches: any[],
  notifyPeriod: EstateNotifyPeriod,
  sharedPartOfEvent: any,
) => {
  const idsWithFields = watches
    .filter((item) => item.notifyPeriod === notifyPeriod)
    .map((item) => [item.id, item.field]);
  idsWithFields.forEach(async (item) => {
    await handleDeleteWatch(token, item[0], {
      event: "removeWatch",
      field: item[1],
      period: notifyPeriod,
      ...sharedPartOfEvent,
    });
  });
};
const addWatchPair = async (
  token: string,
  id: string,
  notifyPeriod: EstateNotifyPeriod,
  sharedPartOfEvent: any,
) => {
  await handleCreateEstateWatch(token, id, "PRICE", notifyPeriod, {
    event: "saveWatch",
    field: "PRICE",
    period: notifyPeriod,
    ...sharedPartOfEvent,
  });
  await handleCreateEstateWatch(token, id, "STATE", notifyPeriod, {
    event: "saveWatch",
    field: "STATE",
    period: notifyPeriod,
    ...sharedPartOfEvent,
  });
};

export const fetchUser = async (token: string, dispatch: (p: any) => void) => {
  if (token) {
    try {
      const result = await handleFetchCurrentUser(token);
      if (result.ok) {
        dispatch(setUserData(result.user || {}));
      }
      // eslint-disable-next-line
    } catch (e) {}
  }
};

export const handleSubmit = async (
  token: string,
  id: string,
  watches: any[],
  original: any[],
  selected: any[],
  setMessage: (s: string) => void,
  setSuccess: (b: boolean) => void,
  setActive: (b: boolean) => void,
  dispatch: (p: any) => void,
  isFavorite: boolean,
  userData: any,
  data: any,
  settings: Maybe<Settings>,
  position: number,
) => {
  try {
    const { title, company, price, disposition } = data;

    const sharedPartOfEvent: any = {
      section: "list",
      project: title,
      developer: company,
      price: price,
      id,
      variant: disposition,
      count: position,
      settings,
    };

    if (selected.some((index) => index === true) && !isFavorite) {
      await handleAddFavoriteEstates(token, [id], getPrimaryEmail(userData), [
        {
          event: "saveFavorite",
          ...sharedPartOfEvent,
        },
      ]);
    }
    //check IMMEDIATELY change
    if (original[0] === false && selected[0] === true)
      await addWatchPair(token, id, "IMMEDIATELY", sharedPartOfEvent);
    if (original[0] === true && selected[0] === false)
      await deleteWatchPair(token, watches, "IMMEDIATELY", sharedPartOfEvent);
    //check DAILY change
    if (original[1] === false && selected[1] === true)
      await addWatchPair(token, id, "DAILY", sharedPartOfEvent);
    if (original[1] === true && selected[1] === false)
      await deleteWatchPair(token, watches, "DAILY", sharedPartOfEvent);
    //check WEEKLY change
    if (original[2] === false && selected[2] === true)
      await addWatchPair(token, id, "WEEKLY", sharedPartOfEvent);
    if (original[2] === true && selected[2] === false)
      await deleteWatchPair(token, watches, "WEEKLY", sharedPartOfEvent);

    setSuccess(true);
    setUpdatedMessage("Strážny pes aktualizovaný", setMessage);
    // eslint-disable-next-line
  } catch (e) {
    setSuccess(false);
    setUpdatedMessage("Vyskytla sa chyba", setMessage);
  }
  setActive(true);
  fetchUser(token, dispatch);
};

export const selectHandle = (
  selected: any[],
  setSelected: (arr: any) => void,
  i: number,
  b: boolean,
) => {
  const arr = [...selected];
  arr[i] = b;
  setSelected(arr);
};
export const ignoreClick = (e: any) => {
  e.preventDefault();
  e.stopPropagation();
};
