import { IUserAuth } from "@/redux/user/auth/types";

export const getUserToken = (s: IUserAuth) => s.userAuth.userToken;

export const getUserRefreshToken = (s: IUserAuth) =>
  s.userAuth.userRefreshToken;

export const getUserData = (s: IUserAuth) => s.userAuth.userData;

export const getIsLoginFormOpen = (s: IUserAuth) => s.userAuth.isLoginFormOpen;

export const getCookies = (s: IUserAuth) => s.userAuth.cookies;
