import { FunctionComponent } from "react";
import styles from "./navigation.module.css";
import { useSelector } from "react-redux";
import { getIsLoginFormOpen } from "@/redux/user/auth/selectors";

type Props = {
  left: FunctionComponent<any>;
  middle?: FunctionComponent<any>;
  right: FunctionComponent<any>;
};

const Navigation: FunctionComponent<Props> = ({
  left: Left,
  middle: Middle,
  right: Right,
}) => {
  const isLoginFormActive = useSelector(getIsLoginFormOpen);

  return (
    <div data-loginform={isLoginFormActive} className={styles.container}>
      <Left />
      {Middle ? <Middle /> : <div />}
      <Right />
    </div>
  );
};

export default Navigation;
