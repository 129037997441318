import f from "@/utils/network/gql/f";
import graphDatabaseBatchRequestStrict from "@/utils/network/request/presets/graphDatabaseBatchRequestStrict";
import query, { Vars } from "./queries/queryEstate";

const queryString = query("Estate")((vars) => [
  f("estate", [
    ["id", vars.id],
    ["source", `"${process.env.NEXT_PUBLIC_API_PRICELIST}"`],
  ])([
    "id",
    "project",
    "developer",
    "price",
    "currency",
    "disposition",
    "area",
    "locality",
    `thumbnailUrl(size: "${"425x240"}")`,
    "detailUrl",
  ]),
]);

export type Data = {
  estate: {
    id: string;
    project: string;
    developer: string;
    price: number;
    currency: string;
    disposition: string[];
    area: string;
    locality: string;
    thumbnailUrl: string;
    detailUrl: string;
  };
};

const estate = (token: string, vars: Vars[]) =>
  graphDatabaseBatchRequestStrict<Data, Vars>(queryString, vars, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

export default estate;
