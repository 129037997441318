import graphRequest from "./graphRequest";
import { v4 as uuid } from "uuid";

const graphDatabaseRequest = <Data, Vars>(
  query: string,
  variables: Vars,
  options?: RequestInit,
) => {
  return graphRequest<Data, Vars>(
    process.env.NEXT_PUBLIC_FLATZONE_API_URL || "",
    query,
    variables,
    {
      ...options,
      headers: {
        ...(options?.headers || {}),
        "X-FZ-Website-ID":
          process.env.NEXT_PUBLIC_FLATZONE_API_WEBSITE_ID || "",
        "X-Request-ID": uuid(),
      },
    },
  );
};

export default graphDatabaseRequest;
