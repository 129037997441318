import q from "@/utils/network/gql/q";
import {
  SortOptionInput,
  SavedSearchSortOptionInput,
} from "@/types/api/complex";

export type Vars = {
  sortOptFavEstates?: SortOptionInput[];
  sortOptSavFilters?: SavedSearchSortOptionInput[];
};

export const VARS = {
  sortOptFavEstates: "[SortOptionInput] = []",
  sortOptSavFilters: "[SavedSearchSortOptionInput] = []",
};

const query = (name: string) => q(name, VARS);

export default query;
