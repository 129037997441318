import styles from "./user.module.css";

const UserIcon = () => {
  return (
    <div className={styles.container}>
      <svg
        version="1.1"
        id="Layer_1"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        x="0px"
        y="0px"
        width="22.805px"
        height="25.197px"
        viewBox="0 0 22.805 25.197"
        enableBackground="new 0 0 22.805 25.197"
        xmlSpace="preserve"
      >
        <g>
          <defs>
            <rect id="SVGID_1_" width="22.805" height="25.197" />
          </defs>
          <clipPath id="SVGID_2_">
            <use xlinkHref="#SVGID_1_" overflow="visible" />
          </clipPath>
          <path
            clipPath="url(#SVGID_2_)"
            fillRule="evenodd"
            clipRule="evenodd"
            fill="#2B2A29"
            d="M14.324,13.518l-0.122-0.001   c1.827-1.221,3.069-3.598,3.069-6.322c0-3.537,0-7.195-5.87-7.195C5.533,0,5.533,3.658,5.533,7.195   c0,2.725,1.242,5.101,3.069,6.322l-0.124,0.001c-3.192,0.447-5.394,1.708-6.731,3.85C0.389,19.542,0.101,22.417,0,24.679   c-0.006,0.136,0.043,0.267,0.137,0.364c0.093,0.099,0.222,0.153,0.358,0.153H22.31c0.135,0,0.264-0.055,0.358-0.153   c0.093-0.098,0.143-0.229,0.137-0.364c-0.102-2.263-0.39-5.139-1.747-7.312C19.72,15.226,17.518,13.965,14.324,13.518 M6.522,7.195   c0-3.963,0.227-6.205,4.879-6.205c4.653,0,4.88,2.242,4.88,6.205c0,3.421-2.188,6.205-4.88,6.205   C8.711,13.4,6.522,10.616,6.522,7.195 M11.401,14.39c0.87,0,1.696-0.233,2.439-0.651l-2.439,3.529l-2.44-3.529   C9.706,14.157,10.532,14.39,11.401,14.39 M1.016,24.207c0.307-5.299,1.722-8.768,7.299-9.663l2.68,3.876   c0.092,0.134,0.244,0.214,0.407,0.214s0.315-0.08,0.407-0.214l2.679-3.876c5.577,0.896,6.992,4.363,7.302,9.663H1.016z"
          />
        </g>
      </svg>
    </div>
  );
};

export default UserIcon;
