type Data = {
  offerTypes: string[];
  estateTypes: string[];
  locations: string[];
  count?: number;
  dispositions?: string[];
};

const createEventCategory = (isProject: boolean, data: Data) => {
  const offerTypes = data.offerTypes.join("_").toLowerCase();
  const estateTypes = data.estateTypes.join("_").toLowerCase();
  const locations = data.locations.join("_");
  const base = `novostavby/${offerTypes}/${estateTypes}/${locations}`;

  if (isProject) {
    return `${base}/projekt_${data.count || 0}`;
  } else {
    return `${base}/projekt_${(data.dispositions || [])
      .join("_")
      .toLowerCase()}`;
  }
};

export default createEventCategory;
