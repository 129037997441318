import f from "@/utils/network/gql/f";
import graphDatabaseRequestStrict from "@/utils/network/request/presets/graphDatabaseRequestStrict";
import mutation, { Vars } from "./mutations/mutationDeleteWatch";

const queryString = mutation("deleteWatch")((vars) => [
  f("deleteWatch", [["id", vars.id]])(["watchId"]),
]);

export type Data = {
  deleteWatch: {
    watchId: string;
  };
};

const deleteWatch = (token: string, vars: Vars) =>
  graphDatabaseRequestStrict<Data, Vars>(queryString, vars, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

export default deleteWatch;
