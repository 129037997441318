import { Location } from "@/types/api/complex";
import { Range } from "@/types/api/simple";
import { Maybe } from "@/types/general";
import { GoogleSortKeys } from "@/utils/google/mapSort";
import { GoogleLevelKeys } from "@/utils/google/mapLevel";

type Data = {
  estateTypes: string[];
  location: Maybe<Partial<Location>>;
  dispositions: string[];
  price: Maybe<Range>;
  changed: boolean;
  sort: GoogleSortKeys;
  view: "square-projects" | "square-detail" | "square" | "table" | "row";
  level: GoogleLevelKeys;
};

const createEventList = (data: Data) => {
  const estateTypes = data.estateTypes.join("_").toLowerCase();
  const locations = [
    data.location?.country || "",
    data.location?.region || "",
    "",
    data.location?.city || "",
    "",
    data.location?.street || "",
  ].join("~");
  const dispositions = (data.dispositions || []).join("_").toLowerCase();
  const priceMin = data.price?.min || "";
  const priceMax = data.price?.max || "";
  const changed = data.changed ? 1 : 0;
  const sort = data.sort;
  const view = data.view;
  const level = data.level;

  return `${estateTypes}~${locations}~${dispositions}~${priceMin}~${priceMax}~${changed}~${sort}~${view}~${level}`;
};

export default createEventList;
