import f from "@/utils/network/gql/f";
import graphDatabaseRequestStrict from "@/utils/network/request/presets/graphDatabaseRequestStrict";
import mutation, { Vars } from "./mutations/mutationLoginWithEmail";

const queryString = mutation("Login")((vars) => [
  f("login", [
    ["usernameOrEmail", vars.usernameOrEmail],
    ["password", vars.password],
  ])(["token, refreshToken"]),
]);

export type Data = {
  login: {
    token: string;
    refreshToken: string;
  };
};

const loginWithEmail = (vars: Vars) =>
  graphDatabaseRequestStrict<Data, Vars>(queryString, vars);

export default loginWithEmail;
