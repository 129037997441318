import sendPasswordResetEmail from "@/api/user/sendPasswordResetEmail";
import resetPassword from "@/api/user/resetPassword";
import fetchCurrentUser from "@/api/user/fetchCurrentUser";
import changePassword from "@/api/user/changePassword";
import updatePersonalData from "@/api/user/updatePersonalData";
import { PersonalDataInput } from "@/types/api/complex";

import addPhone from "@/api/user/addPhone";
import deletePhone from "@/api/user/deletePhone";
import setPrimaryPhone from "@/api/user/setPrimaryPhone";

import addEmail from "@/api/user/addEmail";
import deleteEmail from "@/api/user/deleteEmail";
import setPrimaryEmail from "@/api/user/setPrimaryEmail";
import sendVerificationEmail from "@/api/user/sendVerificationEmail";
import verifyEmail from "@/api/user/verifyEmail";

const handleAddEmail = async (
  token: string,
  idOrEmail: string,
  email: string,
) => {
  const { errors } = await addEmail(token, {
    idOrEmail,
    email,
  });
  if (errors) return errors[0].message || "Nastala chyba";
  return true;
};

const handleDeleteEmail = async (
  token: string,
  idOrEmail: string,
  email: string,
) => {
  const { errors } = await deleteEmail(token, {
    idOrEmail,
    email,
  });
  if (errors) return errors[0].message || "Nastala chyba";
  return true;
};

const handleSetPrimaryEmail = async (
  token: string,
  idOrEmail: string,
  email: string,
) => {
  const { errors } = await setPrimaryEmail(token, {
    idOrEmail,
    email,
  });
  if (errors) return errors[0].message || "Nastala chyba";
  return true;
};

const handleSendVerificationEmail = async (token: string, email: string) => {
  const { errors } = await sendVerificationEmail(token, {
    email,
  });
  if (errors) return errors[0].message || "Nastala chyba";
  return true;
};

const handleVerifyEmail = async (token: string) => {
  const { errors } = await verifyEmail({
    token,
  });
  if (errors) return errors[0].message || "Nastala chyba";
  return true;
};

const handleSendPasswordResetEmail = async (email: string) => {
  const { errors } = await sendPasswordResetEmail({
    email,
  });
  if (errors) return errors[0].message || "Nastala chyba";
  return true;
};

const handleResetPassword = async (password: string, urlToken: string) => {
  const { data, errors } = await resetPassword({
    password,
    token: urlToken,
  });
  const response = {
    ok: false,
    mess: "",
    token: "",
    refreshToken: "",
  };
  if (errors)
    return {
      ...response,
      ok: false,
      mess: errors[0].message || "Nastala chyba",
    };

  const { token, refreshToken } = data.resetPassword;
  return { ...response, ok: true, token, refreshToken };
};
const handleFetchCurrentUser = async (userToken: string) => {
  const { data, errors } = await fetchCurrentUser(userToken);
  const response = {
    ok: false,
    mess: "",
    user: null,
  };

  if (errors)
    return {
      ...response,
      ok: false,
      mess: errors[0].message || "Nastala chyba",
    };
  return { ...response, ok: true, user: data.currentUser };
};

const handleChangePassword = async (
  token: string,
  idOrEmail: string,
  newPassword: string,
  oldPassword: string,
) => {
  const { errors } = await changePassword(token, {
    idOrEmail,
    newPassword,
    oldPassword,
  });
  if (errors) return errors[0].message || "Nastala chyba";
  return true;
};
const handleUpdatePersonalData = async (
  token: string,
  idOrEmail: string,
  data: PersonalDataInput,
  version: number,
) => {
  const { errors } = await updatePersonalData(token, {
    idOrEmail,
    data,
    version,
  });
  if (errors) return errors[0].message || "Nastala chyba";
  return true;
};

const handleAddPhone = async (
  token: string,
  idOrEmail: string,
  number: string,
) => {
  const { errors } = await addPhone(token, {
    idOrEmail,
    number,
  });
  if (errors) return errors[0].message || "Nastala chyba";
  return true;
};
const handleDeletePhone = async (
  token: string,
  idOrEmail: string,
  number: string,
) => {
  const { errors } = await deletePhone(token, {
    idOrEmail,
    number,
  });
  if (errors) return errors[0].message || "Nastala chyba";
  return true;
};
const handleSetPrimaryPhone = async (
  token: string,
  idOrEmail: string,
  number: string,
) => {
  const { errors } = await setPrimaryPhone(token, {
    idOrEmail,
    number,
  });
  if (errors) return errors[0].message || "Nastala chyba";
  return true;
};

export {
  handleSendPasswordResetEmail,
  handleResetPassword,
  handleFetchCurrentUser,
  handleChangePassword,
  handleUpdatePersonalData,
  handleAddPhone,
  handleDeletePhone,
  handleSetPrimaryPhone,
  handleDeleteEmail,
  handleAddEmail,
  handleSetPrimaryEmail,
  handleSendVerificationEmail,
  handleVerifyEmail,
};
