import { SortTypeKeys } from "@/types/api/derived";

export const CURRENCY_KEYS = ["CZK", "EUR"] as const;

export const SORT_FIELD_KEYS = [
  "TYPE",
  "SUBTYPE",
  "OFFER_TYPE",
  "DEVELOPER",
  "PROJECT",
  "NUMBER",
  "ENERGY_CLASS",
  "FLOOR",
  "AREA",
  "PRICE_PER_M2",
  "FIRST_SEEN",
  "LAST_SEEN",
  "DISPOSITION",
  "PPC",
  "PRICE",
  "_SCORE",
  "_DISTANCE",
] as const;

export const SORT_ORDER_KEYS = ["ASC", "DESC"] as const;

export const SORT_OPTION_KEYS = ["top", "price_asc", "price_desc"] as const;

export const SORT_OPTIONS: { value: SortTypeKeys; content: string }[] = [
  {
    value: "top",
    content: "TOP",
  },
  {
    value: "price_asc",
    content: "Najlacnejšie",
  },
  {
    value: "price_desc",
    content: "Najdrahšie",
  },
];

export const OFFER_TYPE_KEYS = ["SALE", "RENT", "EXCHANGE", "AUCTION"] as const;

export const OFFER_TYPE_URLS = [
  "predaj",
  "prenajom",
  "vymena",
  "aukce",
] as const;

export const OFFER_TYPE_TRANSLATIONS = [
  "predaj",
  "prenájom",
  "výměna",
  "aukce",
] as const;

export const ESTATE_TYPE_KEYS = [
  "HOUSE",
  "FLAT",
  "COMMERCIAL",
  "LAND",
  "OTHER",
] as const;

export const ESTATE_TYPE_URLS = [
  "domy",
  "byty",
  "komercni",
  "pozemky",
  "ine",
] as const;

export const ESTATE_TYPE_TRANSLATIONS = [
  "dům",
  "byt",
  "komerční",
  "pozemek",
  "iné",
] as const;

export const OWNERSHIP_KEYS = [
  "PRIVATE",
  "COOPERATIVE",
  "COUNCIL",
  "OTHER",
] as const;

export const CONSTRUCTION_KEYS = [
  "BRICK",
  "MIXED",
  "PANEL",
  "PREFABRICATED",
  "SKELETON",
  "STONE",
  "WOODEN",
] as const;

export const CONDITION_KEYS = [
  "IN_PREPARATION",
  "UNDER_CONSTRUCTION",
  "NEW",
  "RENOVATED",
  "VERY_GOOD",
  "GOOD",
  "POOR",
  "VERY_POOR",
  "FOR_RENOVATION",
] as const;

export const BREADCRUMB_KEYS = [
  "country",
  "region",
  "district",
  "city",
] as const;

export const DISPOSITION_KEYS = [
  "_1",
  "_2",
  "_3",
  "_4",
  "_5",
  "_6",
  "OTHER",
] as const;

export const DISPOSITION_URLS = ["1", "2", "3", "4", "5", "6", "ine"] as const;

export const DISPOSITION_TRANSLATIONS = [
  "1",
  "2",
  "3",
  "4",
  "5",
  "6",
  "iné",
] as const;

export const ENERGY_CLASS_KEYS = ["A", "B", "C", "D", "E", "F", "G"] as const;

export const SORT_TYPE_KEYS = ["top", "price_asc", "price_desc"] as const;

export const SORT_TYPE_OBJECTS = [
  {
    field: "PPC",
    order: "DESC",
  },
  {
    field: "PRICE",
    order: "ASC",
  },
  {
    field: "PRICE",
    order: "DESC",
  },
] as const;

export const VIEW_KEYS = ["projects", "cards", "rows", "table", "map"] as const;

export const JOB_POSITION_KEYS = [
  "ANALYTICS",
  "MARKETING",
  "SALES",
  "MANAGEMENT",
  "OTHER",
] as const;

export const ROLE_TYPE_KEYS = ["OWNER", "MEMBER"] as const;

export const PERMISSION_KEYS = [
  "ORGANIZATION_READ",
  "ORGANIZATION_WRITE",
  "ORGANIZATION_DELETE",
  "SUBSCRIPTION_WRITE",
  "REPORT_READ",
  "REPORT_WRITE",
  "REPORT_ARCHIVE",
  "REPORT_DELETE",
  "REPORT_RUN",
] as const;

export const SAVED_SEARCH_SORT_FIELD_KEYS = [
  "TYPE",
  "DESCRIPTION",
  "CREATED",
  "QUERY",
] as const;
export const SAVED_SEARCH_TYPE_KEYS = [
  "QUERY",
  "ADDRESS",
  "DISTANCE",
  "BBOX",
  "POLYGONS",
] as const;
