import { useRouter } from "next/router";
import { FunctionComponent } from "react";
import Link from "next/link";

type Link = {
  href: string;
  exact?: boolean;
};

type Props = Link & {
  children?: React.ReactNode;
  other?: Link[];
};

const NavLink: FunctionComponent<Props> = ({
  children,
  exact = false,
  href,
  other = [],
}) => {
  const links: Link[] = [{ href, exact }, ...other];
  const router = useRouter();
  const pathname = router.pathname;
  const active =
    links.findIndex(({ href, exact }: Link) =>
      exact ? pathname === href : pathname.includes(href),
    ) >= 0;

  return (
    <Link href={href} passHref={true} data-active={active}>
      {children}
    </Link>
  );
};

export default NavLink;
