import gql from "../../gql/gql";
import createRequest from "../createRequest";
import parseJSON from "../parsers/parseJSON";
import { v4 as uuid } from "uuid";

const graphDatabaseBatchRequestStrict = <Data, Vars>(
  queryString: string,
  array: Vars[],
  options?: RequestInit,
) => {
  const data = array.map((item) => gql(queryString)<Vars>(item));

  const newOptions = { ...options };
  newOptions.headers = {
    "Content-Type": "application/json",
    "X-FZ-Website-ID": process.env.NEXT_PUBLIC_FLATZONE_API_WEBSITE_ID || "",
    "X-Request-ID": uuid(),
    ...newOptions.headers,
  };
  return createRequest<{ data: Data }[]>(
    process.env.NEXT_PUBLIC_FLATZONE_API_URL || "",
    parseJSON,
    {
      body: JSON.stringify(data),
      method: "POST",
      ...newOptions,
    },
  );
};

export default graphDatabaseBatchRequestStrict;
