import styles from "./login.module.css";
import { useState, FunctionComponent } from "react";

import RegisterSuccess from "@/components/RegisterSuccess";
// import SocialLogin from "@/components/SocialLogin";
import LoginForm from "../LoginForm";
import FormToggler from "../FormToggler";
import HeadButton from "@/components/HeadButton";
import { useDispatch, useSelector } from "react-redux";
import { getIsLoginFormOpen } from "@/redux/user/auth/selectors";
import { setIsLoginFormOpen } from "@/redux/user/auth";

const Login: FunctionComponent = () => {
  const isActive = useSelector(getIsLoginFormOpen);
  const dispatch = useDispatch();
  const [isRegister, setRegister] = useState(false);
  const [error, setError] = useState("");
  const [isSent, setSent] = useState(false);

  const closeAll = () => {
    dispatch(setIsLoginFormOpen(true));
    setSent(false);
    setRegister(false);
    setError("");
  };
  const toggleForm = () => {
    setRegister(!isRegister);
  };
  const toggleClass = () => {
    dispatch(setIsLoginFormOpen(!isActive));
  };

  if (isSent) {
    return <RegisterSuccess close={closeAll} />;
  }

  return (
    <div className={styles.container}>
      <HeadButton toggleClass={toggleClass} />
      {isActive && (
        <div onClick={toggleClass} className={styles.modalOverlay} />
      )}
      {isActive && (
        <div className={styles.modalContainer}>
          <div className={styles.modalSubcontainer}>
            <div className={styles.close} onClick={toggleClass}>
              +
            </div>
            <div className={styles.modalHeading}>
              {isRegister
                ? "ZAREGISTRUJTE SA NA FLAT ZONE"
                : "PRIHLÁSTE SA DO SVOJHO PROFILU"}
            </div>
            {error && <span className={styles.formError}>{error}</span>}
            {/* <SocialLogin isRegister={isRegister} /> */}
            <div className={styles.modalSubheading}>
              {/* {isRegister
                ? "Zaregistrujte sa cez e-mail"
                : "Alebo sa prihláste cez e-mail"} */}
            </div>
            <div>
              <LoginForm
                isRegister={isRegister}
                setSent={setSent}
                setError={setError}
              />
              <FormToggler isRegister={isRegister} toggleForm={toggleForm} />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
export default Login;
