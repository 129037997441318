import f from "@/utils/network/gql/f";
import graphDatabaseRequestStrict from "@/utils/network/request/presets/graphDatabaseRequestStrict";
import mutation, { Vars } from "./mutations/mutationVerifyEmail";

const queryString = mutation("VerifyEmail")((vars) => [
  f("verifyEmail", [["token", vars.token]])(["userId"]),
]);

export type Data = {
  verifyEmail: {
    userId: string;
  };
};

const verifyEmail = (vars: Vars) =>
  graphDatabaseRequestStrict<Data, Vars>(queryString, vars);

export default verifyEmail;
