import { FunctionComponent } from "react";
import styles from "./formtoggler.module.css";

interface Props {
  toggleForm: () => void;
  isRegister: boolean;
}

const FormToggler: FunctionComponent<Props> = ({ toggleForm, isRegister }) => {
  return (
    <div className={styles.modalRegister}>
      {isRegister
        ? "Už máte svoj užívateľský profil?"
        : "Ešte nie ste registrovaný/á?"}{" "}
      <span className={styles.modalRegisterSpan} onClick={toggleForm}>
        {isRegister ? "Prihláste sa!" : "Zaregistrujte sa!"}
      </span>
    </div>
  );
};
export default FormToggler;
