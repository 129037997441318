import { ENVIRONMENT, LANG, SITE_ID } from "@/data/constants";
import {
  getNumberOfSearchWatches,
  getRegistrationDate,
  isLogged,
} from "@/utils/user/getters";
import { getUserData } from "@/redux/user/auth/selectors";
import store from "@/redux/store";
import {
  getSearchSharedLastPage,
  getSearchSharedPageObj,
} from "@/redux/search/shared/selectors";
import Router from "next/router";
import { setSearchSharedLastPage } from "@/redux/search/shared";

export const pageLoaded = () => {
  const state = store.getState();
  const lastPage = getSearchSharedLastPage(state);
  const path = Router.router?.asPath || "";
  const queryIndex = path.indexOf("?");
  const currentPage = queryIndex >= 0 ? path.slice(0, queryIndex) : path;

  if (!!currentPage && lastPage !== currentPage) {
    const userData = getUserData(state);
    const page = getSearchSharedPageObj(state);

    store.dispatch(setSearchSharedLastPage(currentPage));

    return {
      event: "pageLoaded",
      page: {
        siteId: SITE_ID,
        environment: ENVIRONMENT,
        pageType: page.type,
        pageLanguage: LANG,
        pageName: page.title,
      },
      user: {
        isLogged: isLogged(userData),
        id: userData.id,
        favoriteEstates: userData.favoriteEstates?.length,
        savedSearches: userData.savedSearches?.length,
        savedSearchWatches: getNumberOfSearchWatches(userData),
        favoritePosts: userData.favoritePosts?.length,
        registrationDate: getRegistrationDate(userData),
      },
    };
  }

  return null;
};
