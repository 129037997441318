import addFavoriteEstates from "@/api/user/addFavoriteEstates";
import deleteFavoriteEstates from "@/api/user/deleteFavoriteEstates";
import estate from "@/api/user/estate";

import deleteWatch from "@/api/user/deleteWatch";
import createEstateWatch from "@/api/user/createEstateWatch";
import { EstateNotifyPeriod, EstateField } from "@/types/api/complex";
import {
  pushFavoriteObjects,
  Data as FavoriteData,
} from "./dataLayer/pushFavoriteObject";
import {
  pushWatchObject,
  Data as WatchData,
} from "./dataLayer/pushWatchObject";

export const handleDeleteWatch = async (
  token: string,
  id: string,
  data?: WatchData,
) => {
  const { errors } = await deleteWatch(token, { id });
  if (errors) return errors[0].message || "Nastala chyba";
  if (data) pushWatchObject(data);
  return true;
};
export const handleCreateEstateWatch = async (
  token: string,
  estateId: string,
  field: EstateField,
  notifyPeriod: EstateNotifyPeriod,
  data?: WatchData,
) => {
  const { errors } = await createEstateWatch(token, {
    estateId,
    field,
    notifyPeriod,
  });
  if (errors) return errors[0].message || "Nastala chyba";
  if (data) pushWatchObject(data);
  return true;
};
type Id = {
  id: string;
};
export const handleEstates = async (token: string, estates: Id[]) => {
  const result = await estate(token, estates);
  return result;
};

export const handleAddFavoriteEstates = async (
  token: string,
  estates: string[],
  idOrEmail: string,
  data: FavoriteData[],
) => {
  const { errors } = await addFavoriteEstates(token, { idOrEmail, estates });
  if (errors) return errors[0].message || "Nastala chyba";
  pushFavoriteObjects(data);
  return true;
};
export const handleDeleteFavoriteEstates = async (
  token: string,
  estates: string[],
  idOrEmail: string,
  data: FavoriteData[],
) => {
  const { errors } = await deleteFavoriteEstates(token, { idOrEmail, estates });
  if (errors) return errors[0].message || "Nastala chyba";
  pushFavoriteObjects(data);
  return true;
};
