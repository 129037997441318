const isEmailValid = (email: string): boolean => {
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(email);
};

const isPassValid = (pass: string): boolean => {
  const str = pass;

  const isLong = str.length > 8;
  const isUpper = str.length - str.replace(/[A-Z]/g, "").length > 0;
  const isLower = str.length - str.replace(/[a-z]/g, "").length > 0;
  const isNumber = str.length - str.replace(/[0-9]/g, "").length > 0;

  return isLong && isUpper && isLower && isNumber;
};

const isPass2Valid = (pass1: string, pass2: string): boolean => {
  return pass1 === pass2;
};
const AreInputsValid = (
  isRegister: boolean,
  email: string,
  pass: string,
  pass2: string,
): boolean => {
  if (isRegister) {
    return (
      isEmailValid(email) && isPassValid(pass) && isPass2Valid(pass, pass2)
    );
  } else {
    return isEmailValid(email) && isPassValid(pass);
  }
};

export { isEmailValid, isPassValid, isPass2Valid, AreInputsValid };
