import m from "@/utils/network/gql/m";

export type Vars = {
  usernameOrEmail: string;
  password: string;
};

export const VARS = {
  usernameOrEmail: "String!",
  password: "String!",
};

const mutation = (name: string) => m(name, VARS);

export default mutation;
