import { Location } from "@/types/api/complex";

export type GoogleLevelKeys =
  | "country"
  | "region"
  | "city"
  | "street"
  | "undefined";

const MAP: Record<string, Exclude<GoogleLevelKeys, "undefined">> = {
  country: "country",
  region: "region",
  city: "city",
  street: "street",
};

const KEYS = Object.keys(MAP);
const LENGTH = KEYS.length;

const mapLevel = (location: Partial<Location> | undefined): GoogleLevelKeys => {
  if (location) {
    let i = -1;
    while (++i < LENGTH) {
      // @ts-ignore
      const k = location[KEYS[i]];
      if (k) return MAP[k];
    }
  }

  return "city";
};

export default mapLevel;
