import f from "@/utils/network/gql/f";
import graphDatabaseRequestStrict from "@/utils/network/request/presets/graphDatabaseRequestStrict";
import mutation, { Vars } from "./mutations/mutationRegisterWithEmail";

const queryString = mutation("Register")((vars) => [
  f("register", [
    ["email", vars.email],
    ["password", vars.password],
  ])(["userId"]),
]);

export type Data = {
  register: {
    userId?: string;
  };
};

const registerWithEmail = (vars: Vars) =>
  graphDatabaseRequestStrict<Data, Vars>(queryString, vars);

export default registerWithEmail;
