import NextHead from "next/head";
import { FunctionComponent } from "react";
import { useRouter } from "next/router";
import { SITE_NAME } from "@/data/constants";
import { Maybe } from "@/types/general";
import pushEvent from "@/utils/other/pushEvent";
import { pageLoaded } from "@/utils/dataLayer/events/pageLoaded";
import { TSearchShared } from "@/redux/search/shared/types";
import { useDispatch } from "react-redux";
import { setSearchSharedPageObj } from "@/redux/search/shared";

type Props = {
  type: TSearchShared["page"]["type"];
  title: string;
  description?: Maybe<string>;
  image?: Maybe<string>;
};

const GTM_ID = process.env.NEXT_PUBLIC_GTM_ID || "";

const Meta: FunctionComponent<Props> = ({
  title,
  description,
  type,
  image,
}) => {
  const dispatch = useDispatch();
  const router = useRouter();
  const base = process.env.NEXT_PUBLIC_CANONICAL_URL;

  dispatch(
    setSearchSharedPageObj({
      title,
      type,
    }),
  );

  description = description || "";
  image = image || `${base}/images/og_image.jpg`;

  const canonical = base + router.asPath;

  pushEvent(pageLoaded());

  return (
    <NextHead>
      {/* Static */}
      <meta charSet="UTF-8" key={"charset"} />
      <meta
        name="viewport"
        content="width=device-width, initial-scale=1, maximum-scale=1, user-scalable=0"
        key={"viewport"}
      />
      <meta name="robots" content="index, follow" key={"robots"} />
      <meta property="og:site_name" content={SITE_NAME} />
      <link
        rel="shortcut icon"
        type="image"
        href="/favicon.ico"
        key={"favicon"}
      />
      <link
        rel="preconnect"
        href="https://api-test.flatzone.cz"
        key={"pre:api"}
      />
      <link
        rel="preconnect"
        href="https://maps.googleapis.com"
        key={"pre:maps"}
      />
      <link
        rel="preconnect"
        href="https://www.googletagmanager.com"
        key={"pre:tag"}
      />
      <link
        rel="preconnect"
        href="https://www.googleadservices.com"
        key={"pre:google"}
      />

      {/* GTM */}
      {/* eslint-disable @next/next/next-script-for-ga */}
      <script
        dangerouslySetInnerHTML={{
          __html: `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
            new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
            j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
            'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
            })(window,document,'script','dataLayer','${GTM_ID}');`,
        }}
      />
      {/* Dynamic */}
      <link rel="canonical" href={canonical} key={"canonical"} />
      <title key={"title"}>{title}</title>
      <meta name="description" content={description} key={"description"} />
      <meta property="og:url" content={canonical} key={"og:url"} />
      <meta property="og:type" content={"website"} key={"og:type"} />
      <meta property="og:image" content={image} key={"og:image"} />
      <meta property="og:title" content={title} key={"og:title"} />
      <meta
        property="og:description"
        content={description}
        key={"og:description"}
      />
      <meta name="twitter:title" content={title} key={"tw:title"} />
      <meta
        name="twitter:description"
        content={description}
        key={"tw:description"}
      />
      <meta name="twitter:image" content={image} key={"tw:image"} />
    </NextHead>
  );
};

export default Meta;
