import { ISearchShared } from "@/redux/search/shared/types";

export const getSearchSharedQuery = (s: ISearchShared) => s.searchShared.query;

export const getSearchSharedSettings = (s: ISearchShared) =>
  s.searchShared.settings;

export const getSearchSharedFilters = (s: ISearchShared) =>
  s.searchShared.filters;

export const getSearchSharedView = (s: ISearchShared) =>
  s.searchShared.settings?.current.view;

export const getSearchSharedSort = (s: ISearchShared) =>
  s.searchShared.settings?.current.sort;

export const getSearchSharedPage = (s: ISearchShared) =>
  s.searchShared.settings?.current.page || 1;

export const getSearchSharedLoading = (s: ISearchShared) =>
  s.searchShared.loading;

export const getSearchSharedType = (s: ISearchShared) => s.searchShared.type;

export const getSearchSharedNumOfProjects = (s: ISearchShared) =>
  s.searchShared.numOfProjects;

export const getSearchSharedHasChanged = (s: ISearchShared) =>
  s.searchShared.hasChanged;

export const getSearchSharedPageObj = (s: ISearchShared) => s.searchShared.page;

export const getSearchSharedIgnoreEstateImpression = (s: ISearchShared) =>
  s.searchShared.ignoreEstateImpression;

export const getSearchSharedLastPage = (s: ISearchShared) =>
  s.searchShared.lastPage;
